/*
TODO tomorrow
fix loading bar 
renaming/commenting/formatting code
better formating, side bar should have a divider
processed image data
*/

import React, { useEffect, useRef, useState} from 'react';
import OpenSeadragon from 'openseadragon';
import { Divider } from '@material-ui/core';

const ImageViewer = ({ selectedProcessedImage }) => {
    const [showPrediction, setShowPrediction] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const viewerRef = useRef(null);
    const viewportPosition = useRef(null);
    const [jsonData, setJsonData] = useState(null);

    useEffect(() => {
        const fetchJsonData = async () => {
            try {
                const { uri, sample_name } = selectedProcessedImage;
                const base_name = sample_name.replace(/\.[^/.]+$/, "");
                const jsonUrl = `${uri}/cell_tables/${base_name}.json`;
                
                const response = await fetch(jsonUrl);
                if(!response.ok) {
                    throw new Error('Failed to fetch json data');
                }
                const data = await response.json();
                setJsonData(data);
            } catch {
                console.error('Failed to fetch json data');
            }
        };

        if (selectedProcessedImage) {
            console.log(selectedProcessedImage);
            setIsLoading(true);

            fetchJsonData();

            const { uri, stain_list, sample_name, v_ihc, v_multiplex } = selectedProcessedImage;
            const multiplexString= v_multiplex ? "vMultiplex" : `vIHC-${stain_list}`;
            let imageUrl;

            if (showPrediction) { 
                imageUrl = `${uri}/${multiplexString}/${sample_name.split('.').slice(0, -1).join('.')}.dzi`;
            } else {
                imageUrl = `${uri}/${sample_name.split('.').slice(0, -1).join('.')}_original.dzi`; 
            }

            if(viewerRef.current) {
                viewportPosition.current = viewerRef.current.viewport.getBounds();
                viewerRef.current.destroy();
                viewerRef.current = null;
            }

            const viewerElement = document.getElementById('openseadragon-viewer');
            if (viewerElement) {
                viewerRef.current = OpenSeadragon({
                    id: 'openseadragon-viewer',
                    prefixUrl: 'openseadragon-images/',
                    tileSources: imageUrl,
                    animationTime: 0.5,
                    blendTime: 0.1,
                    minZoomLevel: 1,
                    minZoomImageRatio: 1,
                    visibilityRatio: 1,
                    zoomPerScroll: 2,
                    constrainDuringPan: true,
                    showHomeControl: false,
                    showZoomControl: false,
                    showNavigationControl: false,
                    showSequenceControl: false,
                    showNavigator: true,
                    navigatorPosition: 'TOP_RIGHT',
                    navigatorSizeRatio: 0.2,
                    immediateRender: true, // might remove
                    timeout: 600000,
                });

                viewerRef.current.addHandler('open', () => {
                    setIsLoading(false);
                    if(viewportPosition.current) {
                        viewerRef.current.viewport.fitBounds(viewportPosition.current, true);
                    }
                });
            }
        }
    }, [selectedProcessedImage, showPrediction]);

    const handleToggle = () => {
        setShowPrediction((prev) => !prev);
    };

    const handleDownload = async () => {
        const { uri, stain_list, sample_name, v_multiplex } = selectedProcessedImage;
        const multiplexString= v_multiplex ? "vMultiplex" : `vIHC-${stain_list}`;

        const link = document.createElement('a');
        const base_name = sample_name.replace(/\.[^/.]+$/, "");
        const extension = sample_name.split('.').pop();
        
        if (showPrediction) {
            link.href = `${uri}/${multiplexString}/${sample_name}`; 
            link.download = `${base_name}-vIHC.${extension}`;
        } else {
            link.href = `${uri.split('/stained/')[0]}/unstained/${sample_name}`; 
            link.download = `${base_name}-H&E.${extension}`;
        }
        link.click();
    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div id='openseadragon-viewer' style={{ height: 'calc(100vh - 66px)', width: 'calc(100vh - 66px)' }}></div>
            <Divider orientation="vertical" flexItem style={{ height: 'calc(100vh - 66px)' }} />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginLeft: '20px',
                width: 'calc(100vw - 33vw - (-66px + 16px + 20px + 100vh))',
                height: 'calc(100vh - 66px)'
            }}>
                <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: '30px' }}>
                    <input 
                        type="checkbox" 
                        checked={showPrediction} 
                        onChange={handleToggle} 
                        disabled={isLoading} 
                        style={{ marginRight: '10px', transform: 'scale(1.2)', cursor: 'pointer' }}
                    />
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Display Predicition</span>
                </label>
                {selectedProcessedImage ? (
                    <div style={{ 
                        padding: '20px', 
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        border: '1px solid #ccc', 
                        borderRadius: '8px', 
                        width: '75%',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)' 
                    }}>
                        <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '20px' }}>Image Info</h3>
                        <p><strong>Description:</strong> {selectedProcessedImage.description || 'No description available'}</p>
                        <p><strong>Tags:</strong> {selectedProcessedImage.tags?.length ? selectedProcessedImage.tags.join(', ') : 'No tags available'}</p>
                        {jsonData && (
                            <>
                                <p><strong>Total Cells:</strong> {jsonData.total_cells}</p>
                                {jsonData.positive_cells && Object.keys(jsonData.positive_cells).map(stain => (
                                    <div key={stain}>
                                        <p><strong>{stain.toUpperCase()} Positive Cells:</strong> {jsonData.positive_cells[stain]}</p>
                                        <p><strong>{stain.toUpperCase()} Negative Cells:</strong> {jsonData.negative_cells?.[stain]}</p>
                                        <p><strong>{stain.toUpperCase()} Percent Positive:</strong> {jsonData.percentage_positive?.[stain]?.toFixed(2)}%</p>
                                    </div>
                                ))}
                            </>
                        )}
                        {/*<p><strong>Width:</strong> {selectedProcessedImage.width || 'N/A'} mm</p>
                        <p><strong>Height:</strong> {selectedProcessedImage.height || 'N/A'} mm</p> // hide size for now*/}
                    </div>
                ) : (
                    <p>No image selected</p>
                )}
                <button 
                    onClick={handleDownload} 
                    disabled={isLoading} 
                    style={{ 
                        padding: '10px 20px', 
                        fontSize: '16px', 
                        fontWeight: 'bold', 
                        backgroundColor: isLoading ? '#ccc' : '#78909c', 
                        color: 'white', 
                        border: 'none', 
                        borderRadius: '5px', 
                        cursor: isLoading ? 'not-allowed' : 'pointer', 
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        marginBottom: '30px'
                    }}
                >
                    Download Image
                </button>
            </div>
        </div>
    );
};

export default ImageViewer;